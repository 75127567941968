body {
    margin: 0;
}

:root {
    --hForn: Poppins, sans-serif;
    ;
    --darkBl: #050748;
    --pinkDark: #e60072;
    --gradientBg: linear-gradient(to right, #f92c8b 0, #b02cd6 100%);
}

a {
    text-decoration: none;
}

.menuHead {
    .nav-link {
        font-weight: 600;
        text-transform: uppercase;
    }
}

.titielStyle {
    .smallTitle {
        color: var(--pinkDark);
        // font-size: 16px;
        // font-weight: 400;
        // margin: 0 0 15px;
        display: block;
        text-transform: uppercase;
        letter-spacing: .5px;
        // line-height: 26px;
    }

    h2 {
        font-size: 40px;
        line-height: 52px;
        font-family: var(--hForn);
        color: var(--darkBl);
        font-weight: 600;
    }
}

.serviceSection {
    background: rgba(247, 247, 247, 0.151);
    position: relative;
    z-index: 1;
    padding: 3em 0;

    &::before {
        content: "";
        background: url(../images/dotShape.png) center top;
        position: absolute;
        height: 100%;
        right: 0;
        left: 0;
        top: 0;
        width: 100%;
        background-size: cover;
        z-index: -1;
        opacity: 0.5;
    }

    h4 {
        color: var(--darkBl);
        font-weight: 700;
        font-size: 1.8em;
    }

    .servInn {
        background: #e9ddff;
        padding: 2em;
        border-radius: 0.5em;

        p {
            font-size: 14px;
        }


    }

    .col-md-6 {
        &:nth-child(2) {
            .servInn {
                background: #ffdadb !important;
            }
        }

        &:nth-child(3) {
            .servInn {
                background: #d6edff !important;
            }
        }

        &:nth-child(4) {
            .servInn {
                background: #ffede1 !important;
            }
        }
    }
}

.readMore {
    color: #6a6a8e;
    position: relative;
    -webkit-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
    -o-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
    transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
    z-index: 1;

    &::after {
        content: "";
        width: 0;
        height: 1px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        z-index: -1;
        -webkit-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
        -o-transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
        transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
        background: currentColor;
    }

    &:hover {
        color: var(--pinkDark) !important;

        &::after {
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}

.btnstyle {
    background: var(--gradientBg);
    box-shadow: 0 10px 15px 0 rgba(175, 0, 202, .2);
    border-radius: 100px;
    color: #fff;
    font-weight: 600;
    padding: 0 32px;
    line-height: 46px;
    position: relative;
    overflow: hidden;
    border: 0;
    transition: 1.2s cubic-bezier(.17, .85, .438, .99);
    z-index: 1;

    .circle {
        background: rgba(255, 255, 255, .2);
        display: block;
        position: absolute;
        border-radius: 50%;
        top: -10%;
        right: -130px;
        width: 200px;
        height: 200px;
        transition: 1.2s cubic-bezier(.17, .85, .438, .99);
        z-index: -1;
    }

    &:hover {
        color: #fff;

        .circle {
            top: -10%;
            right: -80px;
        }
    }
}

.AboutHome {
    padding: 3em 0;
    background: #f7f9ff;
}

.WhyChooseUsBx {
    padding: 3em 0;
    // background: #f7f9ff;
}

.OurClients {
    .logoClients {
        object-fit: contain;
        max-height: 80px;
    }

    .owl-stage {
        display: flex;
        align-items: center;
    }
}

.FooterBx {
    background: #111;
    padding-top: 3em;
    color: #fff;

    a {
        color: rgba(255, 255, 255, 0.388);

        &:hover {
            color: var(--pinkDark);
        }
    }

    ul {
        margin: 0;
        padding: 0;

        // color: #fff;
        li {
            display: block;
            color: rgba(255, 255, 255, 0.388);

            a {
                color: rgba(255, 255, 255, 0.388);
                padding: 4px 0;
                display: block;
            }
        }
    }

    .border-top {
        border-color: rgba(255, 255, 255, 0.1) !important;
    }
}

.pageStyle {}

.pageBanner {
    background: var(--gradientBg);
    padding: 2em 0;
    color: #fff;
}

.contactForm {
    .contactRight {
        padding: 1.5em;
        // border-radius: 0.4em;
        background: #fff8f2;
        color: #6a6a8e;
        box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, .059), 0 8.2px 8.9px -30px rgba(0, 0, 0, .071), 0 25px 40px -30px rgba(0, 0, 0, .2);

        p {
            margin: 0;
        }

        a {
            font-size: 1.3em;
            color: #6a6a8e;
        }

        &:nth-child(2) {
            background: #fdf0fd;
        }

        &:nth-child(3) {
            background: #eaf2ff;
        } 
        &:nth-child(4) {
            background: #f4f5ff;
        }
    }

}